<template>
  <create-modal-layout
    :loading="loading"
    :success="success"
    :error="error"
    :mode="mode"
    :form-valid="formValid"
    :dialog-state.sync="dialogState"
    i18n-context="administration.clients"
    @form:submit="submitForm"
  >
    <template v-slot:form>
      <v-form
        v-show="!success && !error"
        ref="formClientEdit"
        v-model="formValid"
        lazy-validation
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="mx-auto"
            >
              <card-layout>
                <template v-slot:title>
                  {{ $t('general.contactInfo') }}
                </template>
                <template v-slot:content>
                  <custom-text-field
                    v-model="form.name"
                    :label="$t('administration.clients.name') + ' *'"
                    persistent-hint
                    :rules="[rules.required]"
                    clearable
                    autofocus
                    counter="128"
                    maxlength="128"
                  />
                  <custom-text-field
                    v-model="form.contactName"
                    :label="$t('administration.clients.contactName') + ' *'"
                    persistent-hint
                    :rules="[rules.required]"
                    clearable
                    autofocus
                    counter="128"
                    maxlength="128"
                  />
                  <custom-text-field
                    v-model="form.contactEmail"
                    :label="$t('administration.clients.contactEmail') + ' *'"
                    persistent-hint
                    :rules="[rules.required, rules.email]"
                    clearable
                    counter="128"
                    maxlength="128"
                  />
                  <custom-text-field
                    v-model="form.contactNumber"
                    :label="$t('administration.clients.contactPhone')"
                    persistent-hint
                    :rules="[rules.numeric, rules.minLength(form.contactNumber, 10)]"
                    clearable
                    counter="15"
                    maxlength="15"
                  />
                </template>
              </card-layout>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </create-modal-layout>
</template>

<script>
import api from '@/api/modules/administration'
import { required, email, minLength, numeric } from '@/mixins/validation'
import {
  dialogTriggers,
  dialogData
} from '@/mixins/dialogs'
import { countriesList } from '@/mixins/create/countries'
import CreateModalLayout from '@/components/modals/CreateLayout'
import CardLayout from '@/components/layout/CardLayout'
import CustomTextField from '@/components/inputs/CustomTextField'

export default {
  name: 'EditClientDialog',
  components: {
    CreateModalLayout,
    CardLayout,
    CustomTextField
  },
  mixins: [
    required,
    email,
    minLength,
    numeric,
    dialogTriggers,
    dialogData,
    countriesList
  ],
  props: {
    dialogData: { type: Object, default: () => { return {} } }
  },
  data () {
    return {
      mode: 'edit',
      form: {}
    }
  },
  methods: {
    openInCreateMode () {
      return false
    },
    openInEditMode () {
      this.resetState()
      this.mode = 'edit'
      this.form = { ...this.dialogData }
      this.dialogState = true
    },
    resetState () {
      this.success = false
      this.error = false
      this.formValid = null
      this.submitDisabled = false
      if (this.$refs.formClientEdit) {
        this.$refs.formClientEdit.resetValidation()
      }
    },
    async submitForm () {
      if (this.$refs.formClientEdit.validate()) {
        this.loading = true

        try {
          const response = await api.submitData({ ...this.form })
          this.loading = false
          if (response.status >= 200 && response.status < 300) {
            this.success = true
            this.$emit('refresh')
          } else {
            this.error = true
          }
        } catch (error) {
          this.loading = false
          this.error = true
        }
      }
    }
  }
}
</script>
