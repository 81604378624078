<template>
  <create-modal-layout
    :loading="loading"
    :success="success"
    :error="error"
    :mode="mode"
    :form-valid="formValid"
    :dialog-state.sync="dialogState"
    i18n-context="administration.logo"
    @form:submit="submitForm"
  >
    <template v-slot:form>
      <v-form
        v-show="!success && !error"
        ref="formClientLogo"
        v-model="formValid"
        lazy-validation
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="mx-auto"
            >
              <card-layout>
                <template v-slot:title>
                  {{ $t('administration.clients.logo') }}
                </template>
                <template v-slot:content>
                  <logo-file-input
                    v-model="logo"
                    @change="readAndPreviewLogo"
                  />
                  <v-slide-x-transition>
                    <div v-show="showPreview">
                      <v-divider class="mt-3" />
                      <v-img
                        max-width="350"
                        contain
                        :src="img"
                        class="mx-auto my-6"
                      />
                    </div>
                  </v-slide-x-transition>
                </template>
              </card-layout>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </create-modal-layout>
</template>

<script>
import api from '@/api/modules/administration'
import { mapActions } from 'vuex'
import CreateModalLayout from '@/components/modals/CreateLayout'
import CardLayout from '@/components/layout/CardLayout'
import LogoFileInput from '@/components/inputs/LogoInput'

export default {
  name: 'ClientLogoDialog',
  components: {
    CreateModalLayout,
    CardLayout,
    LogoFileInput
  },
  data () {
    return {
      mode: 'edit',
      formValid: false,
      loading: false,
      success: false,
      error: false,
      dialogState: false,
      logo: null,
      img: null
    }
  },
  computed: {
    showPreview () {
      return !!this.img
    }
  },
  created () {
    this.$bus.on('open:client-logo-dialog', this.openDialog)
  },
  beforeDestroy () {
    this.$bus.off('open:client-logo-dialog', this.openDialog)
  },
  methods: {
    ...mapActions([
      'getActiveClient'
    ]),
    openDialog () {
      this.resetState()
      this.dialogState = true
    },
    resetState () {
      this.success = false
      this.error = false
      this.formValid = null
      this.img = null
      this.logo = null
      if (this.$refs.formClientLogo) {
        this.$refs.formClientLogo.resetValidation()
      }
    },
    cancel () {
      this.dialogState = false
    },
    readAndPreviewLogo (file) {
      const reader = new FileReader()
      reader.onloadstart = () => {
        this.loading = true
      }
      reader.onload = event => {
        this.loading = false
        this.img = event.target.result
      }
      if (file) {
        reader.readAsDataURL(file)
      }
    },
    async submitForm () {
      if (this.$refs.formClientLogo.validate()) {
        this.loading = true
        try {
          const response = await api.submitData({
            logo: this.logo
          })
          if (response.status >= 200 && response.status < 300) {
            this.success = true
            this.getActiveClient()
          } else {
            this.error = true
          }
        } catch (error) {
          this.error = true
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
