<template>
  <card-layout disable-title>
    <template v-slot:content>
      <div class="d-flex flex-column pb-3">
        <title-and-content-row
          :title="$t('administration.clients.name')"
          :content="activeClient.name"
        />
        <v-divider />
        <title-and-content-row
          :title="$t('administration.clients.contactName')"
          :content="activeClient.contactName"
        />
        <v-divider />
        <title-and-content-row
          :title="$t('administration.clients.contactEmail')"
          :content="activeClient.contactEmail"
        />
        <v-divider />
        <title-and-content-row
          :title="$t('administration.clients.contactPhone')"
          :content="activeClient.contactNumber"
        />
        <v-divider />
        <title-and-content-row
          :title="$t('administration.clients.logo')"
        >
          <template v-slot:content>
            <div>
              <v-img
                v-if="activeClient.logo"
                width="250px"
                contain
                :src="activeClient.logo"
              />
            </div>
          </template>
          <template v-slot:action>
            <v-btn
              outlined
              rounded
              small
              @click="$bus.emit('open:client-logo-dialog')"
            >
              {{ $t('general.change') }}
            </v-btn>
          </template>
        </title-and-content-row>
        <client-logo-dialog />
      </div>
    </template>
  </card-layout>
</template>

<script>
import CardLayout from '@/components/layout/CardLayout'
import TitleAndContentRow from '@/components/layout/TitleAndContentRow'
import ClientLogoDialog from '@/components/modals/administration/ClientLogo'

export default {
  name: 'CompanyBasicInfo',
  components: {
    CardLayout,
    TitleAndContentRow,
    ClientLogoDialog
  },
  props: {
    activeClient: { type: Object, default: () => { return {} } }
  }
}
</script>
