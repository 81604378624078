<template>
  <custom-file-input
    v-model="localLogo"
    prepend-icon=""
    accept="image/png, image/jpeg"
    :label="$t('inputs.image')"
    :hint="$t('administration.logo.create.hint')"
    persistent-hint
    :clearable="false"
    :rules="[rules.required, rules.fileSize(localLogo, 500000)]"
    @change="$emit('change', $event)"
  />
</template>

<script>
import { required, fileSize } from '@/mixins/validation'
import CustomFileInput from '@/components/inputs/CustomFileInput'

export default {
  name: 'LogoFileInput',
  components: {
    CustomFileInput
  },
  mixins: [
    required,
    fileSize
  ],
  computed: {
    localLogo: {
      get () {
        return this.$attrs.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
