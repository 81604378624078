<template>
  <v-container
    fluid
    class="pt-0"
  >
    <v-row>
      <v-col cols="12">
        <details-toolbar
          :title="$t('administration.dashboard.toolbar.title')"
          :show-divider="false"
        >
          <template v-slot:custom-action>
            <v-btn
              icon
              :disabled="!hasClientPermission('change_client')"
              @click="$bus.emit('open:edit-dialog')"
            >
              <v-icon>
                {{ $vuetify.icons.values.edit }}
              </v-icon>
            </v-btn>
          </template>
        </details-toolbar>
        <company-basic-info
          v-show="activeClientDataIsReady"
          :active-client="activeClient"
        />
      </v-col>
      <edit-client-dialog
        :dialog-data="activeClient"
        @refresh="getActiveClient"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DetailsToolbar from '@/components/toolbars/DetailsToolbar'
import CompanyBasicInfo from '@/features/administration/components/BasicInfo'
import EditClientDialog from '@/components/modals/administration/EditClient'

export default {
  name: 'ClientsDashboard',
  components: {
    DetailsToolbar,
    CompanyBasicInfo,
    EditClientDialog
  },
  data () {
    return {
      activeTab: null
    }
  },
  computed: {
    ...mapGetters([
      'activeClient',
      'hasClientPermission',
      'activeClientDataIsReady'
    ])
  },
  methods: {
    ...mapActions([
      'getActiveClient'
    ])
  }
}
</script>
