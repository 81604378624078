<script>
import { VFileInput } from 'vuetify/lib'

export default {
  name: 'CustomFileInput',
  extends: VFileInput,
  props: {
    outlined: { type: Boolean, default: true },
    showSize: { type: Boolean, default: true },
    counter: { type: Boolean, default: true }
  }
}
</script>
